@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Supercell";
    src: url('./fonts/supercell-magic-webfont.ttf') format('truetype');
  }

  body {
    font-family: 'Supercell', sans-serif;
  }